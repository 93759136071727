import React from 'react';
import './Top100.css';
import Top100Row from './Top100Row';
function Top100Column(props) {
  const range = [...Array(props.count).keys()].map(x => x + props.start);
  let rows = range.map(val => {
    return <Top100Row account={props.account} key={val} val={val} top100={props.top100} />
  });
  return (
    <div>
      {rows}
    </div>
  );
}

export default Top100Column;
