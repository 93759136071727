import React from 'react';
import * as loggingUtils from './utils/loggingUtils';
import './Top100.css';

function Top100Row(props) {
  let ind = props.val - 1;
  let title = "Title";
  let platform = "Platform";
  let artist = "Artist";
  let oneRank = "--";
  let twoRank = "--";
  let threeRank = "--";
  let timeOnLeaderboard = "--";
  if(ind < props.top100.length) {
    title = props.top100[ind].name;
    platform = props.top100[ind].platform;
    artist = props.top100[ind].artist;
    oneRank = props.top100[ind]["1rank"];
    twoRank = props.top100[ind]["2rank"];
    threeRank = props.top100[ind]["3rank"];
    timeOnLeaderboard = props.top100[ind]["weeksOnLeaderboard"];
  }

  function onClickShareLink() {
    if(artist !== "Artist" || title !== "Title") {
      let artistSanitized = encodeURIComponent(artist.trim());
      let songNameSanitized = encodeURIComponent(title.trim());
      let shareLink = "https://www.tunes.wtf/" + artistSanitized + "_" + songNameSanitized;
      loggingUtils.logEvent(
        props.account != null ? props.account.address : 'wallet_unconnected_user',
        'top_100',
        'top_100_link_click',
        'info',
        "https://the402.xyz",
        {
          link: shareLink,
        },
      );
      window.open(shareLink);
    }
  }

  return (
    <div className = "top100Row-Container">
      <div className = "top100Row">
        <div className="top100-week-rank-container">
          <div className = "top100-numberContainer">
            <div className = "top100-title">
              {props.val}
            </div>
          </div>
        </div>
        <div className="top100-week-rank-container">{oneRank}</div>
        <div className="top100-week-rank-container">{twoRank}</div>
        <div className="top100-week-rank-container">{threeRank}</div>
        <div className = "top100-titlePlatformContainer">
        <div className = "top100-titleContainer">
          <div className = "top100-title" onClick={onClickShareLink}>
            {title}
          </div>
        </div>
        <div className = "top100-subText">
          <div className="top100-artistName">
            {artist}
          </div>
          <div className="top100-platformName">
            {platform}
          </div>
        </div>
        </div>
        <div className="top100-week-rank-container">{timeOnLeaderboard}</div>
      </div>
    </div>
  );
}

export default Top100Row;
