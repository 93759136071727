import React, { Component } from "react";
import "./Top100.css";
import Top100Column from "./Top100Column.js";
import Top100Header from "./Top100Header.js";
import * as loadExistingNFTs from "./utils/loadExistingNFTs";
import * as loggingUtils from "./utils/loggingUtils";
import * as encryptUtils from "./encryptDecryptUtils";
import { FaSpinner } from "react-icons/fa";
import { ConnectButton } from "@rainbow-me/rainbowkit";

class Top100 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: null,
      mmAddress: null,
      catalog: [],
      sounds: [],
      glass: [],
      top100: [],
      itemsByTokenCID: {},
    };
  }

  async componentDidUpdate(prevProps) {
    document.body.classList.add("hot100RedBackground");
    if (this.props.account !== prevProps.account) {
      if (this.props.account != null) {
        let soundInfo = await loadExistingNFTs.getSoundNFTs(
          this.props.account.address
        );
        let sounds = soundInfo["sounds"];
        let catalog = await loadExistingNFTs.getCatalogNFTs(
          this.props.account.address
        );
        let glassInfo = await loadExistingNFTs.getGlassNFTs(
          this.props.account.address
        );
        let glass = glassInfo["glass"];
        let itemsByTokenCID = {};
        for (let i = 0; i < sounds.length; i++) {
          sounds[i].platform = "Sound";
          itemsByTokenCID[sounds[i]["tokenCID"]] = sounds[i];
        }
        for (let i = 0; i < catalog.length; i++) {
          catalog[i].platform = "Catalog";
          itemsByTokenCID[catalog[i]["tokenCID"]] = catalog[i];
        }
        for (let i = 0; i < glass.length; i++) {
          glass[i].platform = "Glass";
          itemsByTokenCID[glass[i]["tokenCID"]] = glass[i];
        }
        let top100 = await encryptUtils.getTop100();
        for (let i = 0; i < top100.length; i++) {
          let top100Song = top100[i];
          let streams = top100Song.totalWeeklyStreams;
          if (top100Song["mediaItem"]["id"] in itemsByTokenCID) {
            top100Song = itemsByTokenCID[top100Song["mediaItem"]["id"]];
            top100Song.totalWeeklyStreams = streams;
            top100[i] = top100Song;
          } else {
            top100[i] = null;
          }
        }
        top100 = top100.filter((x) => x).slice(0, 100);
        top100 = await encryptUtils.getTop100Rank(top100);
        this.setState({
          catalog,
          glass,
          sounds,
          top100,
          itemsByTokenCID,
        });

        loggingUtils.logEvent(
          this.props.account.address,
          "top_100",
          "top_100_page_load",
          "info",
          "https://the402.xyz",
          {}
        );
      }
    }
  }

  async componentDidMount() {
    document.body.classList.add("hot100RedBackground");
    if (this.props.account != null) {
      let soundInfo = await loadExistingNFTs.getSoundNFTs(
        this.props.account.address
      );
      let sounds = soundInfo["sounds"];
      let catalog = await loadExistingNFTs.getCatalogNFTs(
        this.props.account.address
      );
      let glassInfo = await loadExistingNFTs.getGlassNFTs(
        this.props.account.address
      );
      let glass = glassInfo["glass"];
      let itemsByTokenCID = {};
      for (let i = 0; i < sounds.length; i++) {
        sounds[i].platform = "Sound";
        itemsByTokenCID[sounds[i]["tokenCID"]] = sounds[i];
      }
      for (let i = 0; i < catalog.length; i++) {
        catalog[i].platform = "Catalog";
        itemsByTokenCID[catalog[i]["tokenCID"]] = catalog[i];
      }
      for (let i = 0; i < glass.length; i++) {
        glass[i].platform = "Glass";
        itemsByTokenCID[glass[i]["tokenCID"]] = glass[i];
      }
      let top100 = await encryptUtils.getTop100();
      for (let i = 0; i < top100.length; i++) {
        let top100Song = top100[i];
        let streams = top100Song.totalWeeklyStreams;
        if (top100Song["mediaItem"]["id"] in itemsByTokenCID) {
          top100Song = itemsByTokenCID[top100Song["mediaItem"]["id"]];
          top100Song.totalWeeklyStreams = streams;
          top100[i] = top100Song;
        } else {
          top100[i] = null;
        }
      }
      top100 = top100.filter((x) => x).slice(0, 100);
      top100 = await encryptUtils.getTop100Rank(top100);
      this.setState({
        catalog,
        glass,
        sounds,
        top100,
        itemsByTokenCID,
      });

      loggingUtils.logEvent(
        this.props.account.address,
        "top_100",
        "top_100_page_load",
        "info",
        "https://the402.xyz",
        {}
      );
    }
  }

  render() {
    return (
      <div className="top100-body">
        {this.props.account != null ? (
          <div className="top100-connectWalletContainer">
            <ConnectButton showBalance={false} chainStatus="none" />
          </div>
        ) : null}
        {this.props.account != null && this.state.top100.length > 0 ? (
          <div>
            <div className="top100MetaverseHeaderContainer">
              <div className="top100MetaverseHeader">
                <div className="top100MetaverseThe">THE</div>
                <div className="top100Metaverse">METAVERSE</div>
              </div>
            </div>
            <div className="top100-bodyContainer">
              <Top100Header />
              <div className="top100Column-Container">
                <div className="top100Column1Container">
                  <div className="top100-week-box">
                    <div className="top100-week-column-container-thisweek">
                      <div className="top100-week-column-thisweek">
                        THIS WEEK
                      </div>
                    </div>
                    <div className="top100-week-column-container">
                      <div className="top100-week-column">
                        1 week ago
                      </div>
                    </div>
                    <div className="top100-week-column-container">
                      <div className="top100-week-column">
                        2 weeks ago
                      </div>
                    </div>
                    <div className="top100-week-column-container">
                      <div className="top100-week-column">
                        3 weeks ago
                      </div>
                    </div>
                    <div className="top100columnType">
                      <div>
                      TITLE
                      </div>
                      <div>
                      ARTIST
                      </div>
                      <div>
                      PLATFORM
                      </div>
                    </div>
                    <div className="top100-week-column-container-weeksonchart">
                      <div className="top100-week-column">
                        Weeks on Chart
                      </div>
                    </div>
                  </div>
                  <Top100Column
                    account={this.props.account}
                    start={1}
                    count={33}
                    top100={this.state.top100}
                  />
                </div>
                <div className="middleColumnContainer">
                  <Top100Column
                    account={this.props.account}
                    start={34}
                    count={33}
                    top100={this.state.top100}
                  />
                </div>
                <Top100Column
                  account={this.props.account}
                  start={67}
                  count={34}
                  top100={this.state.top100}
                />
              </div>
            </div>
          </div>
        ) : this.props.account != null ? (
          <div className="top100-emptyState">
            <FaSpinner className="top100Spinner" />
          </div>
        ) : (
          <div className="top100-emptyState">
            <div className="top100MetaverseHeaderContainer">
              <div className="top100MetaverseHeader">
                <div className="top100MetaverseThe">THE</div>
                <div className="top100Metaverse">METAVERSE</div>
              </div>
            </div>
            <Top100Header />
            <div className="emptyStateMessage">Connect your wallet to view</div>
            <div className="connectButtonEmptyState">
              <ConnectButton showBalance={false} chainStatus="none" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Top100;
