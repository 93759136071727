import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Top100 from "./Top100";
import { useAccount } from "wagmi";

function AppWithRainbow(props) {
  const { data: account } = useAccount();
  return (
    <Router>
      <Switch>
        <Route
          exact path="/"
          render={(props) => (
            <Top100 account={account} />
          )}
        />
      </Switch>
    </Router>
  );
}

export default AppWithRainbow;