import React from 'react';
import './Top100.css';

function Top100Header(props) {
  return (
    <div className = "top100Header-Container">
      <div className = "top100Header">
        HOT 100
      </div>
    </div>
  );
}

export default Top100Header;
