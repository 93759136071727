import LitJsSdk from "lit-js-sdk";
import axios from "axios";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";

import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import { fromString as uint8arrayFromString } from "uint8arrays/from-string";
import {
  contractAddress,
  leaderboardContractAddress,
  hypeRaceLeaderboardContractAddress,
  wethContractAddress,
  gasGiftingContractAddress,
  mainnetContractAddress,
  mainnetGasGiftingContractAddress,
  mainnetWethContractAddress,
} from "./config";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";

const { REACT_APP_PUBLIC_KEY, REACT_APP_PRIVATE_KEY, REACT_APP_API_URL, REACT_APP_PROD_API_URL, REACT_APP_IS_MAINNET } = process.env;

let web3;
console.log(REACT_APP_IS_MAINNET);
if(REACT_APP_IS_MAINNET !== "testnet") {
  web3 = createAlchemyWeb3(REACT_APP_PROD_API_URL);
} else {
  web3 = createAlchemyWeb3(REACT_APP_API_URL);
}
const contract = require("./artifacts/contracts/PPV.sol/PPV.json");
let ppvContract;
if(REACT_APP_IS_MAINNET !== "testnet") {
  ppvContract = new web3.eth.Contract(contract.abi, mainnetContractAddress);
} else {
  ppvContract = new web3.eth.Contract(contract.abi, contractAddress);
}
const contract2 = require("./artifacts/contracts/Leaderboard.sol/Leaderboard.json");
const leaderboardContract = new web3.eth.Contract(
  contract2.abi,
  leaderboardContractAddress
);
const hypeRaceLeaderboardContract = new web3.eth.Contract(
  contract2.abi,
  hypeRaceLeaderboardContractAddress
);
let wethContract;
const contract3 = require("./artifacts/contracts/WETHTest.sol/WETHTest.json");
if(REACT_APP_IS_MAINNET !== "testnet") {
  wethContract = new web3.eth.Contract(
    contract3.abi,
    mainnetWethContractAddress
  );
} else {
  wethContract = new web3.eth.Contract(
    contract3.abi,
    wethContractAddress
  );
}
const contract4 = require("./artifacts/contracts/GasGifting.sol/GasGifting.json");
let gasGiftingContract;
if(REACT_APP_IS_MAINNET !== "testnet") {
  gasGiftingContract = new web3.eth.Contract(contract4.abi, mainnetGasGiftingContractAddress);
} else {
  gasGiftingContract = new web3.eth.Contract(contract4.abi, gasGiftingContractAddress);
}
const { ethers } = require("ethers");

let graphURL;
if(REACT_APP_IS_MAINNET !== "testnet") {
  graphURL = "https://api.thegraph.com/subgraphs/name/narahari28/the402"
} else {
  graphURL = "https://api.thegraph.com/subgraphs/name/narahari28/ppvtest"
}

export async function getCurrentGasPrice() {
  let data;
  if(REACT_APP_IS_MAINNET !== "testnet") {
    data = await axios.get("https://gasstation-mainnet.matic.network/v2");
  } else {
    data = await axios.get("https://gasstation-mumbai.matic.today/v2");
  }
  if (data != null) {
    console.log(data);
    return parseInt(data["data"]["fast"]["maxPriorityFee"] * 1000000000);
  } else {
    return null;
  }
}

export async function getENS(address) {
  var name = await ethers.getDefaultProvider().lookupAddress(address);
  if (name == null) {
    return (
      address.substring(0, 5) + "..." + address.substring(address.length - 5)
    );
  }
  return name;
}

export function getAuthUUID() {
  return uuidv4();
}

export async function getLeaderboardData(tokenCID, listener) {
  if (listener != null) {
    listener = listener.toLowerCase();
  }
  let query = `
        {
            mediaItem(id:${'"' + tokenCID + '"'}){
                upvotes,
                userData(where:{user:${'"' + listener + '"'}}){
                  userMediaVote,
                }
            }
        }
    `;
  let data = await axios.post(
    "https://api.thegraph.com/subgraphs/name/narahari28/leaderboardtest",
    { query }
  );
  let totalUpvotes = 0;
  let userVote = false;
  if (data["data"]["data"]["mediaItem"] != null) {
    totalUpvotes = data["data"]["data"]["mediaItem"]["upvotes"];
    if (data["data"]["data"]["mediaItem"]["userData"].length > 0) {
      userVote =
        data["data"]["data"]["mediaItem"]["userData"][0]["userMediaVote"];
    }
  }
  return {
    totalUpvotes: totalUpvotes,
    userVote: userVote,
  };
}

export async function getHyperaceLeaderboardData(tokenCID, listener) {
  if (listener != null) {
    listener = listener.toLowerCase();
  }
  let query = `
        {
            mediaItem(id:${'"' + tokenCID + '"'}){
                upvotes,
                userData(where:{user:${'"' + listener + '"'}}){
                  userMediaVote,
                }
            }
        }
    `;
  let data = await axios.post(
    "https://api.thegraph.com/subgraphs/name/narahari28/hyperaceleaderboardtest",
    { query }
  );
  let totalUpvotes = 0;
  let userVote = false;
  if (data["data"]["data"]["mediaItem"] != null) {
    totalUpvotes = data["data"]["data"]["mediaItem"]["upvotes"];
    if (data["data"]["data"]["mediaItem"]["userData"].length > 0) {
      userVote =
        data["data"]["data"]["mediaItem"]["userData"][0]["userMediaVote"];
    }
  }
  return {
    totalUpvotes: totalUpvotes,
    userVote: userVote,
  };
}

export async function getLeaderboardDataBulk(items, listener, uriAttribute) {
  if(listener == null) {
    return items;
  }
  listener = listener.toLowerCase();
  let tokenCIDs = [];
  for(let i = 0; i < items.length; i++) {
    let item = items[i];
    if(item[uriAttribute] == null) {
      continue;
    }
    let uriSplit = item[uriAttribute].split("/");
    let tokenCID = uriSplit[uriSplit.length - 1];
    tokenCIDs.push('"' + tokenCID + '"');
  }
  let query = `
    {
      mediaItems(where: {id_in:${'[' + tokenCIDs + ']'}}){
        id,
        upvotes,
        userData(where:{user:${'"' + listener + '"'}}){
          userMediaVote,
        }
      }
    }
  `;
  let data = await axios.post(
    "https://api.thegraph.com/subgraphs/name/narahari28/leaderboardtest",
    { query }
  );
  let allUpvoteInfo = {}
  for(let i = 0; i < data["data"]["data"]["mediaItems"].length; i++) {
    let datum = data["data"]["data"]["mediaItems"][i];
    let id = datum["id"];
    let totalUpvotes = datum["upvotes"];
    let userVote = false;
    if(datum["userData"].length > 0) {
      userVote = datum["userData"][0]["userMediaVote"];
    }
    allUpvoteInfo[id] = {
      totalUpvotes: totalUpvotes,
      userVote: userVote,
    }
  }
  for(let i = 0; i < items.length; i++) {
    let item = items[i];
    if(item[uriAttribute] == null) {
      item["totalUpvotes"] = 0;
      item["userVote"] = false;
      items[i] = item;
      continue;
    }
    let uriSplit = item[uriAttribute].split("/");
    let tokenCID = uriSplit[uriSplit.length - 1];
    if(tokenCID in allUpvoteInfo) {
      item["totalUpvotes"] = allUpvoteInfo[tokenCID]["totalUpvotes"];
      item["userVote"] = allUpvoteInfo[tokenCID]["userVote"];
    } else {
      item["totalUpvotes"] = 0;
      item["userVote"] = false;
    }
    items[i] = item;
  }
  return items;
}

export async function getHyperaceLeaderboardDataBulk(items, listener, uriAttribute) {
  if(listener == null) {
    return items;
  }
  listener = listener.toLowerCase();
  let tokenCIDs = [];
  for(let i = 0; i < items.length; i++) {
    let item = items[i];
    if(item[uriAttribute] == null) {
      continue;
    }
    let uriSplit = item[uriAttribute].split("/");
    let tokenCID = uriSplit[uriSplit.length - 1];
    tokenCIDs.push('"' + tokenCID + '"');
  }
  let query = `
    {
      mediaItems(where: {id_in:${'[' + tokenCIDs + ']'}}){
        id,
        upvotes,
        userData(where:{user:${'"' + listener + '"'}}){
          userMediaVote,
        }
      }
    }
  `;
  let data = await axios.post(
    "https://api.thegraph.com/subgraphs/name/narahari28/hyperaceleaderboardtest",
    { query }
  );
  let allUpvoteInfo = {}
  for(let i = 0; i < data["data"]["data"]["mediaItems"].length; i++) {
    let datum = data["data"]["data"]["mediaItems"][i];
    let id = datum["id"];
    let totalUpvotes = datum["upvotes"];
    let userVote = false;
    if(datum["userData"].length > 0) {
      userVote = datum["userData"][0]["userMediaVote"];
    }
    allUpvoteInfo[id] = {
      totalUpvotes: totalUpvotes,
      userVote: userVote,
    }
  }
  for(let i = 0; i < items.length; i++) {
    let item = items[i];
    if(item[uriAttribute] == null) {
      item["totalUpvotes"] = 0;
      item["userVote"] = false;
      items[i] = item;
      continue;
    }
    let uriSplit = item[uriAttribute].split("/");
    let tokenCID = uriSplit[uriSplit.length - 1];
    if(tokenCID in allUpvoteInfo) {
      item["totalUpvotes"] = allUpvoteInfo[tokenCID]["totalUpvotes"];
      item["userVote"] = allUpvoteInfo[tokenCID]["userVote"];
    } else {
      item["totalUpvotes"] = 0;
      item["userVote"] = false;
    }
    items[i] = item;
  }
  return items;
}

export async function getTop100Rank(top100) {
  console.log("top100 of current week:");
  console.log(top100);
  var nowLuxon = DateTime.now().setZone("America/New_York");
  var now = new Date();
  now.setYear(nowLuxon.year);
  now.setMonth(nowLuxon.month - 1);
  now.setDate(nowLuxon.day);
  now.setHours(nowLuxon.hour);
  now.setMinutes(nowLuxon.minute);
  var start = new Date(now.getFullYear(), 0, 1);
  var startLuxon = DateTime.fromJSDate(start).setZone("America/New_York");
  start = new Date();
  start.setYear(startLuxon.year);
  start.setMonth(startLuxon.month - 1);
  start.setDate(startLuxon.day);
  start.setHours(startLuxon.hour);
  start.setMinutes(startLuxon.minute);
  var dayMilliseconds = 1000 * 60 * 60 * 24;
  var sundays = 1;
  while (start <= now) {
    var day = start.getDay();
    if (day === 0) {
      sundays++;
    }
    start = new Date(+start + dayMilliseconds);
  }
  console.log("starting sun: " + sundays);
  let top100Track = top100;
  let currSun = sundays;
  let currWeek = (sunday) => "2022/" + sunday;
  let getQuery = (week) => `
        {
            weekMediaItems(first:100 where:{week:${
              '"' + week + '"'
            }} orderBy: totalWeeklyStreams, orderDirection: desc){
                mediaItem{
                  id
                },
                totalWeeklyStreams
            }
        }
    `;

  while (currSun >= 22) {
    currSun--;
    let thisWeek = currWeek(currSun);
    let query = getQuery(thisWeek);
    let data = await axios.post(
      "https://api.thegraph.com/subgraphs/name/narahari28/leaderboardtest",
      { query }
    );
    let currMediaItems = data["data"]["data"]["weekMediaItems"];
    console.log("Week: " + thisWeek);
    console.log(currMediaItems);
    top100Track.map((item) => {
      let itemId = item["tokenCID"];
      let indexOfCurr = currMediaItems.findIndex(
        (currItem) => currItem["mediaItem"]["id"] === itemId
      );
      let sunDiff = sundays - currSun;
      if (item["1rank"] === undefined) {
        item["1rank"] = "--";
      }
      if (item["2rank"] === undefined) {
        item["2rank"] = "--";
      }
      if (item["3rank"] === undefined) {
        item["3rank"] = "--";
      }
      if (sunDiff < 4) {
        item[sunDiff + "rank"] =
          indexOfCurr !== -1 ? (indexOfCurr + 1).toString() : "--";
      }
      if (item["weeksOnLeaderboard"] === undefined) {
        item["weeksOnLeaderboard"] = 1;
      }
      if (indexOfCurr !== -1) {
        item["weeksOnLeaderboard"]++;
      }
    });
  }

  return top100Track;
}

export async function getTop100() {
  var nowLuxon = DateTime.now().setZone("America/New_York");
  var now = new Date();
  now.setYear(nowLuxon.year);
  now.setMonth(nowLuxon.month - 1);
  now.setDate(nowLuxon.day);
  now.setHours(nowLuxon.hour);
  now.setMinutes(nowLuxon.minute);
  var start = new Date(now.getFullYear(), 0, 1);
  var startLuxon = DateTime.fromJSDate(start).setZone("America/New_York");
  start = new Date();
  start.setYear(startLuxon.year);
  start.setMonth(startLuxon.month - 1);
  start.setDate(startLuxon.day);
  start.setHours(startLuxon.hour);
  start.setMinutes(startLuxon.minute);
  var dayMilliseconds = 1000 * 60 * 60 * 24;
  var sundays = 1;
  while (start <= now) {
    var day = start.getDay();
    if (day === 0) {
      sundays++;
    }
    start = new Date(+start + dayMilliseconds);
  }
  start = new Date(+start + dayMilliseconds);
  let curWeek = now.getFullYear() + "/" + sundays;
  console.log(curWeek);
  let query = `
        {
            weekMediaItems(first:100 where:{week:${
              '"' + curWeek + '"'
            }} orderBy: totalWeeklyStreams, orderDirection: desc){
                mediaItem{
                  id
                },
                totalWeeklyStreams
            }
        }
    `;
  let data = await axios.post(
    "https://api.thegraph.com/subgraphs/name/narahari28/leaderboardtest",
    { query }
  );
  console.log(data);
  return data["data"]["data"]["weekMediaItems"];
}

export async function getTop100Monthly() {
  let now = new Date();
  let curMonth = now.getFullYear() + "/" + (now.getMonth() + 1);
  let query = `
        {
            monthMediaItems(first:130 where:{month:${
              '"' + curMonth + '"'
            }} orderBy: totalMonthlyStreams, orderDirection: desc){
                mediaItem{
                  id
                },
                totalMonthlyStreams
            }
        }
    `;
  let data = await axios.post(
    "https://api.thegraph.com/subgraphs/name/narahari28/leaderboardtest",
    { query }
  );
  return data["data"]["data"]["monthMediaItems"];
}

export async function getAuthSig() {
  let chain;
  if(REACT_APP_IS_MAINNET !== "testnet") {
    chain = "polygon";
  } else {
    chain = "mumbai";
  }
  return await LitJsSdk.checkAndSignAuthMessage({ chain: chain });
}

export async function getAuthSigDedicatedWallet(privateKey) {
  const AUTH_SIGNATURE_BODY =
    "I am creating an account to use Lit Protocol at {{timestamp}}";

  const privKeyBuffer = uint8arrayFromString(privateKey.substring(2), "base16");
  const now = new Date().toISOString();
  const messageToSign = AUTH_SIGNATURE_BODY.replace("{{timestamp}}", now);
  const wallet = new ethers.Wallet(privKeyBuffer);
  const signature = await wallet.signMessage(messageToSign);
  const recoveredAddress = ethers.utils.verifyMessage(messageToSign, signature);
  return {
    sig: signature,
    derivedVia: "web3.eth.personal.sign",
    signedMessage: messageToSign,
    address: recoveredAddress,
  };
}

export async function encryptSong(songData, authUUID, authSig) {
  const readableStreamForFile = songData[0];
  console.log("File before encrypting");
  console.log(readableStreamForFile);

  const { encryptedFile, symmetricKey } = await LitJsSdk.encryptFile({
    file: readableStreamForFile,
  });

  const client = new LitJsSdk.LitNodeClient();
  await client.connect();
  window.litNodeClient = client;

  let accessCondition;
  for (let i = 0; i < contract["abi"].length; i++) {
    if (contract["abi"][i]["name"] === "isAuthorizedToView") {
      accessCondition = contract["abi"][i];
    }
  }
  let chain;
  let usedContractAddress;
  if(REACT_APP_IS_MAINNET !== "testnet") {
    chain = "polygon";
    usedContractAddress = mainnetContractAddress;
  } else {
    chain = "mumbai";
    usedContractAddress = contractAddress;
  }
  const evmContractConditions = [
    {
      contractAddress: usedContractAddress,
      functionName: "isAuthorizedToView",
      functionParams: [":userAddress", authUUID],
      functionAbi: accessCondition,
      chain,
      returnValueTest: {
        key: "",
        comparator: "=",
        value: "true",
      },
    },
  ];
  const encryptedSymmetricKey = await window.litNodeClient.saveEncryptionKey({
    authSig,
    chain,
    evmContractConditions,
    symmetricKey,
  });

  const encryptedSongReturnObject = {
    encryptedSymmetricKey,
    encryptedBlob: encryptedFile,
  };
  return encryptedSongReturnObject;
}

export async function writeSongPinata(encryptedSongInfo, fileName) {
  const { encryptedBlob } = encryptedSongInfo;

  let fileNameUnderscore = fileName.replaceAll(" ", "_");

  let hashURL = "";
  let pinHash = "";
  let pinFileUrl = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
  const name = `encrypted-${fileNameUnderscore}-data`;

  let data = new FormData();
  data.append("file", encryptedBlob);
  const metadata = JSON.stringify({ name: name + "-blob" });
  data.append("pinataMetadata", metadata);

  await axios
    .post(pinFileUrl, data, {
      headers: {
        pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
        pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_API_KEY,
      },
    })
    .then(function (response) {
      pinHash = response.data.IpfsHash;
    })
    .catch(function (error) {
      console.log("there was an error writing the song to Pinata ", error);
    });

  hashURL = `https://gateway.pinata.cloud/ipfs/${pinHash}`;
  return hashURL.replace("gateway.pinata", "the402.mypinata");
}

export async function writeMetadataPinata(
  blobURL,
  encryptedSymmetricKey,
  itemParams
) {
  const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;

  const title = itemParams.name;
  const description = itemParams.description;
  const image = itemParams.image;
  const imagePinata = itemParams.imagePinata;
  const creatorRoyaltyPercentage = itemParams.creatorRoyaltyPercentage;
  const impressionFee = itemParams.impressionFee * 1000000000000000000;
  const impressionPercentage = itemParams.impressionPercentage;
  const creatorShares = itemParams.creatorShares;
  const creatorName = itemParams.creatorName;
  const creatorAddress = itemParams.creatorAddress;
  const holderShares = itemParams.holderShares;
  const editions = itemParams.editions;
  //mint price stored in Pinata as Wei
  const mintPrice = itemParams.mintPrice * 1000000000000000000;
  console.log(mintPrice);

  let pinHash = "";
  let JSONBody = {
    pinataMetadata: {
      name: title,
    },
    pinataContent: {
      name: title,
      creatorName,
      description,
      image,
      imagePinata,
      encryptedURL: blobURL,
      encryptedSymmetricKey,
      impressionFee,
      impressionPercentage,
      creatorShares,
      holderShares,
      creatorRoyaltyPercentage,
      creatorAddress,
      editions,
      mintPrice,
    },
  };

  await axios
    .post(url, JSONBody, {
      headers: {
        pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
        pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_API_KEY,
      },
    })
    .then(function (response) {
      pinHash = response.data.IpfsHash;
    })
    .catch(function (error) {
      console.log("there was an error writing the NFT to Pinata ", error);
    });

  return pinHash;
}

export async function writeFilePinata(fileName, file) {
  let data = new FormData();
  const url = "https://api.pinata.cloud/pinning/pinFileToIPFS";
  let returnUrl = "";

  let fileNameUnderscore = fileName.replaceAll(" ", "_");
  const title = `${fileNameUnderscore}-image`;

  data.append("file", file[0]);

  const metadata = JSON.stringify({
    name: title,
  });

  data.append("pinataMetadata", metadata);

  await axios
    .post(url, data, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
        pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_API_KEY,
      },
    })

    .then(function (response) {
      const pinHash = response.data.IpfsHash;
      returnUrl = `https://gateway.pinata.cloud/ipfs/${pinHash}`;
    })
    .catch(function (error) {
      console.log("there was an error posting to Pinata: ", error);
    });

  return returnUrl;
}

export async function getNFTUrlPinata(fileName) {
  const queryString = `?metadata[name]=${fileName}`;
  const url = `https://api.pinata.cloud/data/pinList${queryString}`;
  let returnUrl = "";

  await axios
    .get(url, {
      headers: {
        pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
        pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_API_KEY,
      },
    })
    .then(function (response) {
      const pinHash = response.data.rows[0].ipfs_pin_hash;
      returnUrl = `https://gateway.pinata.cloud/ipfs/${pinHash}`;
    })
    .catch(function (error) {
      console.log("there was an error getting NFT URL from Pinata: ", error);
    });

  return returnUrl;
}

export async function decryptSong(
  encryptedSymmetricKey,
  encryptedBlob,
  authUUID,
  authSig
) {
  const client = new LitJsSdk.LitNodeClient();
  await client.connect();
  window.litNodeClient = client;

  let accessCondition;
  for (let i = 0; i < contract["abi"].length; i++) {
    if (contract["abi"][i]["name"] === "isAuthorizedToView") {
      accessCondition = contract["abi"][i];
    }
  }
  let chain;
  let usedContractAddress;
  if(REACT_APP_IS_MAINNET !== "testnet") {
    chain = "polygon";
    usedContractAddress = mainnetContractAddress;
  } else {
    chain = "mumbai";
    usedContractAddress = contractAddress;
  }
  const evmContractConditions = [
    {
      contractAddress: usedContractAddress,
      functionName: "isAuthorizedToView",
      functionParams: [":userAddress", authUUID],
      functionAbi: accessCondition,
      chain,
      returnValueTest: {
        key: "",
        comparator: "=",
        value: "true",
      },
    },
  ];
  const symmetricKey = await window.litNodeClient.getEncryptionKey({
    evmContractConditions,
    toDecrypt: LitJsSdk.uint8arrayToString(encryptedSymmetricKey, "base16"),
    chain,
    authSig,
  });

  let decryptedSong = "";

  try {
    decryptedSong = await LitJsSdk.decryptFile({
      file: encryptedBlob,
      symmetricKey,
    });
  } catch (error) {
    console.log(
      "Error: Incorrect symmetric key, encrypted content, or do not meet conditions.\n",
      error
    );
  }

  return decryptedSong;
}

export async function hasGiftedGas(address) {
  return await gasGiftingContract.methods.hasUserBeenGiftedGas(address).call();
}

export async function giftGas(recipient) {
  let hasGiftedGasToUser = await hasGiftedGas(recipient);
  if(hasGiftedGasToUser) {
    return;
  }
  const nonce = await web3.eth.getTransactionCount(
    process.env.REACT_APP_PUBLIC_KEY,
    "pending"
  ); //get latest nonce
  const maxGasPrice = await getCurrentGasPrice();
  let usedContractAddress;
  if(REACT_APP_IS_MAINNET !== "testnet") {
    usedContractAddress = mainnetGasGiftingContractAddress;
  } else {
    usedContractAddress = gasGiftingContractAddress
  }
  //the transaction
  const tx = {
    from: process.env.REACT_APP_PUBLIC_KEY,
    to: usedContractAddress,
    nonce: nonce,
    gas: 10000000,
    maxPriorityFeePerGas: maxGasPrice,
    data: gasGiftingContract.methods.giftGas(recipient).encodeABI(),
  };

  const signPromise = web3.eth.accounts.signTransaction(
    tx,
    process.env.REACT_APP_PRIVATE_KEY
  );
  signPromise
    .then((signedTx) => {
      web3.eth.sendSignedTransaction(
        signedTx.rawTransaction,
        function (err, hash) {
          if (err) {
            console.log(
              "Something went wrong when submitting your transaction:",
              err
            );
          }
        }
      );
    })
    .catch((err) => {
      console.log(" Promise failed:", err);
    });
}

export async function logUpvote(tokenCID, viewerAddress) {
  const nonce = await web3.eth.getTransactionCount(
    process.env.REACT_APP_PUBLIC_KEY,
    "pending"
  ); //get latest nonce
  const maxGasPrice = await getCurrentGasPrice();

  //the transaction
  const tx = {
    from: process.env.REACT_APP_PUBLIC_KEY,
    to: leaderboardContractAddress,
    nonce: nonce,
    gas: 10000000,
    maxPriorityFeePerGas: maxGasPrice,
    data: leaderboardContract.methods
      .registerUpvote(tokenCID, viewerAddress)
      .encodeABI(),
  };

  const signPromise = web3.eth.accounts.signTransaction(
    tx,
    process.env.REACT_APP_PRIVATE_KEY
  );
  signPromise
    .then((signedTx) => {
      web3.eth.sendSignedTransaction(
        signedTx.rawTransaction,
        function (err, hash) {
          if (err) {
            console.log(
              "Something went wrong when submitting your transaction:",
              err
            );
          }
        }
      );
    })
    .catch((err) => {
      console.log(" Promise failed:", err);
    });
}

export async function logUpvoteHyperace(tokenCID, viewerAddress) {
  const nonce = await web3.eth.getTransactionCount(
    process.env.REACT_APP_PUBLIC_KEY,
    "pending"
  ); //get latest nonce
  const maxGasPrice = await getCurrentGasPrice();

  //the transaction
  const tx = {
    from: process.env.REACT_APP_PUBLIC_KEY,
    to: hypeRaceLeaderboardContractAddress,
    nonce: nonce,
    gas: 10000000,
    maxPriorityFeePerGas: maxGasPrice,
    data: hypeRaceLeaderboardContract.methods
      .registerUpvote(tokenCID, viewerAddress)
      .encodeABI(),
  };

  const signPromise = web3.eth.accounts.signTransaction(
    tx,
    process.env.REACT_APP_PRIVATE_KEY
  );
  signPromise
    .then((signedTx) => {
      web3.eth.sendSignedTransaction(
        signedTx.rawTransaction,
        function (err, hash) {
          if (err) {
            console.log(
              "Something went wrong when submitting your transaction:",
              err
            );
          }
        }
      );
    })
    .catch((err) => {
      console.log(" Promise failed:", err);
    });
}

export async function logRemoveUpvote(tokenCID, viewerAddress) {
  const nonce = await web3.eth.getTransactionCount(
    process.env.REACT_APP_PUBLIC_KEY,
    "pending"
  ); //get latest nonce
  const maxGasPrice = await getCurrentGasPrice();

  //the transaction
  const tx = {
    from: process.env.REACT_APP_PUBLIC_KEY,
    to: leaderboardContractAddress,
    nonce: nonce,
    gas: 10000000,
    maxPriorityFeePerGas: maxGasPrice,
    data: leaderboardContract.methods
      .registerRemoveUpvote(tokenCID, viewerAddress)
      .encodeABI(),
  };

  const signPromise = web3.eth.accounts.signTransaction(
    tx,
    process.env.REACT_APP_PRIVATE_KEY
  );
  signPromise
    .then((signedTx) => {
      web3.eth.sendSignedTransaction(
        signedTx.rawTransaction,
        function (err, hash) {
          if (err) {
            console.log(
              "Something went wrong when submitting your transaction:",
              err
            );
          }
        }
      );
    })
    .catch((err) => {
      console.log(" Promise failed:", err);
    });
}

export async function logRemoveUpvoteHyperace(tokenCID, viewerAddress) {
  const nonce = await web3.eth.getTransactionCount(
    process.env.REACT_APP_PUBLIC_KEY,
    "pending"
  ); //get latest nonce
  const maxGasPrice = await getCurrentGasPrice();

  //the transaction
  const tx = {
    from: process.env.REACT_APP_PUBLIC_KEY,
    to: hypeRaceLeaderboardContractAddress,
    nonce: nonce,
    gas: 10000000,
    maxPriorityFeePerGas: maxGasPrice,
    data: hypeRaceLeaderboardContract.methods
      .registerRemoveUpvote(tokenCID, viewerAddress)
      .encodeABI(),
  };

  const signPromise = web3.eth.accounts.signTransaction(
    tx,
    process.env.REACT_APP_PRIVATE_KEY
  );
  signPromise
    .then((signedTx) => {
      web3.eth.sendSignedTransaction(
        signedTx.rawTransaction,
        function (err, hash) {
          if (err) {
            console.log(
              "Something went wrong when submitting your transaction:",
              err
            );
          }
        }
      );
    })
    .catch((err) => {
      console.log(" Promise failed:", err);
    });
}

export async function logImpression(tokenCID, viewerAddress) {
  var nowLuxon = DateTime.now().setZone("America/New_York");
  var now = new Date();
  now.setYear(nowLuxon.year);
  now.setMonth(nowLuxon.month - 1);
  now.setDate(nowLuxon.day);
  now.setHours(nowLuxon.hour);
  now.setMinutes(nowLuxon.minute);
  var start = new Date(now.getFullYear(), 0, 1);
  var startLuxon = DateTime.fromJSDate(start).setZone("America/New_York");
  start = new Date();
  start.setYear(startLuxon.year);
  start.setMonth(startLuxon.month - 1);
  start.setDate(startLuxon.day);
  start.setHours(startLuxon.hour);
  start.setMinutes(startLuxon.minute);
  var dayMilliseconds = 1000 * 60 * 60 * 24;
  var sundays = 1;
  while (start <= now) {
    var day = start.getDay();
    if (day === 0) {
      sundays++;
    }
    start = new Date(+start + dayMilliseconds);
  }
  let curWeek = now.getFullYear() + "/" + sundays;
  let curMonth = now.getFullYear() + "/" + (now.getMonth() + 1);
  const nonce = await web3.eth.getTransactionCount(
    process.env.REACT_APP_PUBLIC_KEY,
    "pending"
  ); //get latest nonce
  const maxGasPrice = await getCurrentGasPrice();

  //the transaction
  const tx = {
    from: process.env.REACT_APP_PUBLIC_KEY,
    to: leaderboardContractAddress,
    nonce: nonce,
    gas: 10000000,
    maxPriorityFeePerGas: maxGasPrice,
    data: leaderboardContract.methods
      .registerStream(tokenCID, viewerAddress, curMonth, curWeek)
      .encodeABI(),
  };

  const signPromise = web3.eth.accounts.signTransaction(
    tx,
    process.env.REACT_APP_PRIVATE_KEY
  );
  signPromise
    .then((signedTx) => {
      web3.eth.sendSignedTransaction(
        signedTx.rawTransaction,
        function (err, hash) {
          if (err) {
            console.log(
              "Something went wrong when submitting your transaction:",
              err
            );
          }
        }
      );
    })
    .catch((err) => {
      console.log(" Promise failed:", err);
    });
}

export async function logImpressionHyperace(tokenCID, viewerAddress) {
  var nowLuxon = DateTime.now().setZone("America/New_York");
  var now = new Date();
  now.setYear(nowLuxon.year);
  now.setMonth(nowLuxon.month - 1);
  now.setDate(nowLuxon.day);
  now.setHours(nowLuxon.hour);
  now.setMinutes(nowLuxon.minute);
  var start = new Date(now.getFullYear(), 0, 1);
  var startLuxon = DateTime.fromJSDate(start).setZone("America/New_York");
  start = new Date();
  start.setYear(startLuxon.year);
  start.setMonth(startLuxon.month - 1);
  start.setDate(startLuxon.day);
  start.setHours(startLuxon.hour);
  start.setMinutes(startLuxon.minute);
  var dayMilliseconds = 1000 * 60 * 60 * 24;
  var sundays = 1;
  while (start <= now) {
    var day = start.getDay();
    if (day === 0) {
      sundays++;
    }
    start = new Date(+start + dayMilliseconds);
  }
  let curWeek = now.getFullYear() + "/" + sundays;
  let curMonth = now.getFullYear() + "/" + (now.getMonth() + 1);
  const nonce = await web3.eth.getTransactionCount(
    process.env.REACT_APP_PUBLIC_KEY,
    "pending"
  ); //get latest nonce
  const maxGasPrice = await getCurrentGasPrice();

  //the transaction
  const tx = {
    from: process.env.REACT_APP_PUBLIC_KEY,
    to: hypeRaceLeaderboardContractAddress,
    nonce: nonce,
    gas: 10000000,
    maxPriorityFeePerGas: maxGasPrice,
    data: hypeRaceLeaderboardContract.methods
      .registerStream(tokenCID, viewerAddress, curMonth, curWeek)
      .encodeABI(),
  };

  const signPromise = web3.eth.accounts.signTransaction(
    tx,
    process.env.REACT_APP_PRIVATE_KEY
  );
  signPromise
    .then((signedTx) => {
      web3.eth.sendSignedTransaction(
        signedTx.rawTransaction,
        function (err, hash) {
          if (err) {
            console.log(
              "Something went wrong when submitting your transaction:",
              err
            );
          }
        }
      );
    })
    .catch((err) => {
      console.log(" Promise failed:", err);
    });
}

export async function approveAccessMM(publicKey, tokenId, value) {
  const maxGasPrice = await getCurrentGasPrice();

  let chain;
  let usedContractAddress;
  if(REACT_APP_IS_MAINNET !== "testnet") {
    chain = "polygon";
    usedContractAddress = mainnetContractAddress;
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: "0x89",
        rpcUrls: ["https://matic-mainnet.chainstacklabs.com"],
        chainName: "MATIC",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
        blockExplorerUrls: ["https://polygonscan.com/"],
      }],
    });
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x89" }], // MATIC
    });
  } else {
    chain = "mumbai";
    usedContractAddress = contractAddress;
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: "0x13881",
        rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
        chainName: "Mumbai",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
      }], // Mumbai MATIC
    });
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x13881" }], // Mumbai MATIC
    });
  }

  try {
    const approvePromise = new Promise(function (resolve, reject) {
      wethContract.methods.approve(usedContractAddress, value).send({
        from: publicKey,
        gas: 10000000,
        maxPriorityFeePerGas: maxGasPrice,
      })
        .on("confirmation", function(confNumber, receipt, latestBlockHash) {
          resolve(true);
        })
        .on("error", function (error, receipt) {
          resolve(false);
        });
    });
    let didApprove = await approvePromise;
    if(!didApprove) {
      return false;
    }
    const nonce = await web3.eth.getTransactionCount(
      REACT_APP_PUBLIC_KEY,
      "pending"
    ); //get latest nonce
    //the transaction
    const tx = {
      from: REACT_APP_PUBLIC_KEY,
      to: usedContractAddress,
      nonce: nonce,
      gas: 10000000,
      maxPriorityFeePerGas: maxGasPrice,
      data: ppvContract.methods.authorizeAccessToView(tokenId, publicKey).encodeABI(),
    };

    const signPromise = web3.eth.accounts.signTransaction(
      tx,
      REACT_APP_PRIVATE_KEY
    );
    let signedTx = await signPromise;
    const sendPromise = new Promise(function (resolve, reject) {
      web3.eth.sendSignedTransaction(signedTx.rawTransaction)
        .on("confirmation", function(confNumber, receipt, latestBlockHash) {
          resolve(true);
        })
        .on("error", function(error, receipt) {
          resolve(false);
        })
    })
    let didSucceed = await sendPromise;
    return didSucceed;
  } catch (error) {
    return false;
  }
}

export async function isAuthorized(publicKey, authUUID) {
  return await ppvContract.methods.isAuthorizedToView(publicKey, authUUID).call();
}

export async function revokeAccess(address, tokenId) {
  const nonce = await web3.eth.getTransactionCount(
    REACT_APP_PUBLIC_KEY,
    "pending"
  ); //get latest nonce
  const maxGasPrice = await getCurrentGasPrice();

  let usedContractAddress;
  if(REACT_APP_IS_MAINNET !== "testnet") {
    usedContractAddress = mainnetContractAddress;
  } else {
    usedContractAddress = contractAddress;
  }

  //the transaction
  const tx = {
    from: REACT_APP_PUBLIC_KEY,
    to: usedContractAddress,
    nonce: nonce,
    gas: 10000000,
    maxPriorityFeePerGas: maxGasPrice,
    data: ppvContract.methods.revokeAccessToView(address, tokenId).encodeABI(),
  };

  const signPromise = web3.eth.accounts.signTransaction(
    tx,
    REACT_APP_PRIVATE_KEY
  );
  signPromise
    .then((signedTx) => {
      web3.eth.sendSignedTransaction(
        signedTx.rawTransaction,
        function (err, hash) {
          if (!err) {
            console.log(
              "The hash of your transaction is: ",
              hash,
              "\nCheck Alchemy's Mempool to view the status of your transaction!"
            );
          } else {
            console.log(
              "Something went wrong when submitting your transaction:",
              err
            );
          }
        }
      );
    })
    .catch((err) => {
      console.log(" Promise failed:", err);
    });
}

export async function mintToken(publicKey, tokenId, mintPrice) {
  const maxGasPrice = await getCurrentGasPrice();
  let usedContractAddress;
  if(REACT_APP_IS_MAINNET !== "testnet") {
    usedContractAddress = mainnetContractAddress;
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: "0x89",
        rpcUrls: ["https://matic-mainnet.chainstacklabs.com"],
        chainName: "MATIC",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
        blockExplorerUrls: ["https://polygonscan.com/"],
      }],
    });
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x89" }], // MATIC
    });
  } else {
    usedContractAddress = contractAddress;
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: "0x13881",
        rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
        chainName: "Mumbai",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
      }], // Mumbai MATIC
    });
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x13881" }], // Mumbai MATIC
    });
  }
  try {
    const approvePromise = new Promise(function (resolve, reject) {
      wethContract.methods.approve(usedContractAddress, mintPrice).send({
        from: publicKey,
        gas: 10000000,
        maxPriorityFeePerGas: maxGasPrice,
      })
        .on("confirmation", function(confNumber, receipt, latestBlockHash) {
          resolve(true);
        })
        .on("error", function (error, receipt) {
          resolve(false);
        });
    });
    let didApprove = await approvePromise;
    if(!didApprove) {
      return false;
    }
    const nonce = await web3.eth.getTransactionCount(
      REACT_APP_PUBLIC_KEY,
      "pending"
    ); //get latest nonce
    //the transaction
    const tx = {
      from: REACT_APP_PUBLIC_KEY,
      to: usedContractAddress,
      nonce: nonce,
      gas: 10000000,
      maxPriorityFeePerGas: maxGasPrice,
      data: ppvContract.methods.mintTokens(1, tokenId, publicKey).encodeABI(),
    };

    const signPromise = web3.eth.accounts.signTransaction(
      tx,
      REACT_APP_PRIVATE_KEY
    );
    let signedTx = await signPromise;
    const sendPromise = new Promise(function (resolve, reject) {
      web3.eth.sendSignedTransaction(signedTx.rawTransaction)
        .on("confirmation", function(confNumber, receipt, latestBlockHash) {
          resolve(true);
        })
        .on("error", function(error, receipt) {
          resolve(false);
        })
    })
    let didSucceed = await sendPromise;
    return didSucceed;
  } catch (error) {
    return false;
  }
}

export async function listNFT(publicKey, tokenId, price, amount) {
  const maxGasPrice = await getCurrentGasPrice();

  let usedContractAddress;
  if(REACT_APP_IS_MAINNET !== "testnet") {
    usedContractAddress = mainnetContractAddress;
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: "0x89",
        rpcUrls: ["https://matic-mainnet.chainstacklabs.com"],
        chainName: "MATIC",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
        blockExplorerUrls: ["https://polygonscan.com/"],
      }],
    });
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x89" }], // MATIC
    });
  } else {
    usedContractAddress = contractAddress;
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: "0x13881",
        rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
        chainName: "Mumbai",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
      }], // Mumbai MATIC
    });
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x13881" }], // Mumbai MATIC
    });
  }
  let ppvContractAsUser = new web3.eth.Contract(contract.abi, usedContractAddress, {
    from: publicKey,
  });

  try {
    let sendTxPromise = new Promise(function (resolve, reject) {
      ppvContractAsUser.methods
        .createMarketItem(tokenId, price.toString(), amount)
        .send({
          from: publicKey,
          gas: 10000000,
          maxPriorityFeePerGas: maxGasPrice,
        })
        .on("transactionHash", function (hash) {
          resolve(true);
        })
        .on("error", function (error, receipt) {
          resolve(false);
        });
    });
    let didApprove = await sendTxPromise;
    return didApprove;
  } catch (error) {
    return false;
  }
}

export async function delistNFT(publicKey, marketItemId) {
  const maxGasPrice = await getCurrentGasPrice();
  let usedContractAddress;
  if(REACT_APP_IS_MAINNET !== "testnet") {
    usedContractAddress = mainnetContractAddress;
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: "0x89",
        rpcUrls: ["https://matic-mainnet.chainstacklabs.com"],
        chainName: "MATIC",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
        blockExplorerUrls: ["https://polygonscan.com/"],
      }],
    });
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x89" }], // MATIC
    });
  } else {
    usedContractAddress = contractAddress;
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: "0x13881",
        rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
        chainName: "Mumbai",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
      }], // Mumbai MATIC
    });
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x13881" }], // Mumbai MATIC
    });
  }
  let ppvContractAsUser = new web3.eth.Contract(contract.abi, usedContractAddress, {
    from: publicKey,
  });

  try {
    let sendTxPromise = new Promise(function (resolve, reject) {
      ppvContractAsUser.methods
        .delistMarketItem(marketItemId)
        .send({
          from: publicKey,
          gas: 10000000,
          maxPriorityFeePerGas: maxGasPrice,
        })
        .on("transactionHash", function (hash) {
          resolve(true);
        })
        .on("error", function (error, receipt) {
          resolve(false);
        });
    });
    let didApprove = await sendTxPromise;
    return didApprove;
  } catch (error) {
    return false;
  }
}

export async function editPriceNFT(publicKey, marketItemId, newPrice) {
  const maxGasPrice = await getCurrentGasPrice();
  let usedContractAddress;
  if(REACT_APP_IS_MAINNET !== "testnet") {
    usedContractAddress = mainnetContractAddress;
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: "0x89",
        rpcUrls: ["https://matic-mainnet.chainstacklabs.com"],
        chainName: "MATIC",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
        blockExplorerUrls: ["https://polygonscan.com/"],
      }],
    });
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x89" }], // MATIC
    });
  } else {
    usedContractAddress = contractAddress;
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: "0x13881",
        rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
        chainName: "Mumbai",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
      }], // Mumbai MATIC
    });
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x13881" }], // Mumbai MATIC
    });
  }
  let ppvContractAsUser = new web3.eth.Contract(contract.abi, usedContractAddress, {
    from: publicKey,
  });

  try {
    let sendTxPromise = new Promise(function (resolve, reject) {
      ppvContractAsUser.methods
        .updatePriceMarketItem(marketItemId, newPrice.toString())
        .send({
          from: publicKey,
          gas: 10000000,
          maxPriorityFeePerGas: maxGasPrice,
        })
        .on("transactionHash", function (hash) {
          resolve(true);
        })
        .on("error", function (error, receipt) {
          resolve(false);
        });
    });
    let didApprove = await sendTxPromise;
    return didApprove;
  } catch (error) {
    return false;
  }
}

export async function purchaseNFT(publicKey, marketItemId, price) {
  const maxGasPrice = await getCurrentGasPrice();

  let chain;
  let usedContractAddress;
  if(REACT_APP_IS_MAINNET !== "testnet") {
    usedContractAddress = mainnetContractAddress;
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: "0x89",
        rpcUrls: ["https://matic-mainnet.chainstacklabs.com"],
        chainName: "MATIC",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
        blockExplorerUrls: ["https://polygonscan.com/"],
      }],
    });
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x89" }], // MATIC
    });
  } else {
    usedContractAddress = contractAddress;
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: "0x13881",
        rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
        chainName: "Mumbai",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
      }], // Mumbai MATIC
    });
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x13881" }], // Mumbai MATIC
    });
  }

  try {
    const approvePromise = new Promise(function (resolve, reject) {
      wethContract.methods.approve(usedContractAddress, price).send({
        from: publicKey,
        gas: 10000000,
        maxPriorityFeePerGas: maxGasPrice,
      })
        .on("confirmation", function(confNumber, receipt, latestBlockHash) {
          resolve(true);
        })
        .on("error", function (error, receipt) {
          resolve(false);
        });
    });
    let didApprove = await approvePromise;
    if(!didApprove) {
      return false;
    }
    const nonce = await web3.eth.getTransactionCount(
      REACT_APP_PUBLIC_KEY,
      "pending"
    ); //get latest nonce
    //the transaction
    const tx = {
      from: REACT_APP_PUBLIC_KEY,
      to: usedContractAddress,
      nonce: nonce,
      gas: 10000000,
      maxPriorityFeePerGas: maxGasPrice,
      data: ppvContract.methods.buyMarketItem(marketItemId, publicKey).encodeABI(),
    };

    const signPromise = web3.eth.accounts.signTransaction(
      tx,
      REACT_APP_PRIVATE_KEY
    );
    let signedTx = await signPromise;
    const sendPromise = new Promise(function (resolve, reject) {
      web3.eth.sendSignedTransaction(signedTx.rawTransaction)
        .on("confirmation", function(confNumber, receipt, latestBlockHash) {
          resolve(true);
        })
        .on("error", function(error, receipt) {
          resolve(false);
        })
    })
    let didSucceed = await sendPromise;
    return didSucceed;
  } catch (error) {
    return false;
  }
}

export async function getNFTDetails(i) {
  let query = `
        {
            item(id:${'"' + i + '"'}){
                id,
                impressionFee,
                creatorAddress,
                mintLimit,
                mintCount,
                isMintReady,
                isMintComplete,
                tokenCID,
                mintPrice,
                mediaType,
                creatorShares,
                holderShares,
                authUUID
            }
        }
    `;
  let data = await axios.post(
    graphURL,
    { query }
  );
  let item = data["data"]["data"]["item"];
  if (item == null) {
    return null;
  }
  let tokenId = item["id"];
  let impressionFee = item["impressionFee"];
  let creatorAddress = item["creatorAddress"];
  let mintLimit = item["mintLimit"];
  let mintCount = item["mintCount"];
  let isMintReady = item["isMintReady"];
  let isMintComplete = item["isMintComplete"];
  let tokenCID = item["tokenCID"];
  let mintPrice = item["mintPrice"];
  let mediaType = item["mediaType"];
  let creatorShares = item["creatorShares"];
  let holderShares = item["holderShares"];
  let authUUID = item["authUUID"];
  let uri = "https://the402.mypinata.cloud/ipfs/" + tokenCID;
  let metadata = await axios.get(uri);
  return {
    description: metadata.data.description,
    name: metadata.data.name,
    mintPrice,
    imagePinata: metadata.data.imagePinata,
    image: metadata.data.image,
    royalty: metadata.data.creatorRoyaltyPercentage,
    authUUID,
    mintCount,
    mintLimit,
    isMintReady,
    isMintComplete,
    mediaType,
    impressionFee,
    creatorAddress,
    tokenId,
    uri,
    royaltySharesInfo: {
      creatorShares: creatorShares,
      holderShares: holderShares,
    },
  };
}

export async function getBalance(address, i) {
  return await ppvContract.methods.balanceOf(address, i).call();
}

export async function getMintReadyAndCompleteNFTs() {
  let query = `
        {
            items(where:{isMintReady:true}){
                id,
                impressionFee,
                creatorAddress,
                mintLimit,
                mintCount,
                isMintReady,
                isMintComplete,
                tokenCID,
                mintPrice,
                mediaType,
                creatorShares,
                holderShares,
                authUUID
            }
        }
    `;
  let data = await axios.post(
    graphURL,
    { query }
  );
  let items = data["data"]["data"]["items"];
  let ans = [];
  for (let i = 0; i < items.length; i++) {
    let item = items[i];
    let tokenId = item["id"];
    let impressionFee = item["impressionFee"];
    let creatorAddress = item["creatorAddress"];
    let mintLimit = item["mintLimit"];
    let mintCount = item["mintCount"];
    let isMintReady = item["isMintReady"];
    let isMintComplete = item["isMintComplete"];
    let tokenCID = item["tokenCID"];
    let mintPrice = item["mintPrice"];
    let mediaType = item["mediaType"];
    let creatorShares = item["creatorShares"];
    let holderShares = item["holderShares"];
    let authUUID = item["authUUID"];
    let uri = "https://the402.mypinata.cloud/ipfs/" + tokenCID;
    let metadata = await axios.get(uri);
    ans.push({
      description: metadata.data.description,
      name: metadata.data.name,
      mintPrice,
      imagePinata: metadata.data.imagePinata,
      image: metadata.data.image,
      royalty: metadata.data.creatorRoyaltyPercentage,
      authUUID,
      mintCount,
      mintLimit,
      isMintReady,
      isMintComplete,
      mediaType,
      impressionFee,
      creatorAddress,
      tokenId,
      uri,
      royaltySharesInfo: {
        creatorShares: creatorShares,
        holderShares: holderShares,
      },
    });
  }
  return ans;
}

export async function getMintReadyNFTs(mediaType) {
  let query = `
        {
            items(where:{isMintReady:true, isMintComplete:false}){
                id,
                impressionFee,
                creatorAddress,
                mintLimit,
                mintCount,
                isMintReady,
                isMintComplete,
                tokenCID,
                mintPrice,
                mediaType,
                creatorShares,
                holderShares,
                authUUID
            }
        }
    `;
  let data = await axios.post(
    graphURL,
    { query }
  );
  let items = data["data"]["data"]["items"];
  let ans = [];
  for (let i = 0; i < items.length; i++) {
    let item = items[i];
    let tokenId = item["id"];
    let impressionFee = item["impressionFee"];
    let creatorAddress = item["creatorAddress"];
    let mintLimit = item["mintLimit"];
    let mintCount = item["mintCount"];
    let isMintReady = item["isMintReady"];
    let isMintComplete = item["isMintComplete"];
    let tokenCID = item["tokenCID"];
    let mintPrice = item["mintPrice"];
    let authUUID = item["authUUID"];
    let itemMediaType = item["mediaType"];
    if (itemMediaType.toString() !== mediaType.toString()) {
      continue;
    }
    let creatorShares = item["creatorShares"];
    let holderShares = item["holderShares"];
    let uri = "https://the402.mypinata.cloud/ipfs/" + tokenCID;
    let metadata = await axios.get(uri);
    ans.push({
      description: metadata.data.description,
      name: metadata.data.name,
      mintPrice,
      imagePinata: metadata.data.imagePinata,
      image: metadata.data.image,
      royalty: metadata.data.creatorRoyaltyPercentage,
      authUUID,
      mintCount,
      mintLimit,
      isMintReady,
      isMintComplete,
      mediaType: itemMediaType,
      impressionFee,
      creatorAddress,
      tokenId,
      uri,
      royaltySharesInfo: {
        creatorShares: creatorShares,
        holderShares: holderShares,
      },
    });
  }
  return ans;
}

export async function getMintFinishedNFTs(mediaType) {
  let query = `
        {
            items(where:{isMintComplete:true}){
                id,
                impressionFee,
                creatorAddress,
                mintLimit,
                mintCount,
                isMintReady,
                isMintComplete,
                tokenCID,
                mintPrice,
                mediaType,
                creatorShares,
                holderShares,
                authUUID
            }
        }
    `;
  let data = await axios.post(
    graphURL,
    { query }
  );
  let items = data["data"]["data"]["items"];
  console.log(data);
  console.log(items);
  let ans = [];
  for (let i = 0; i < items.length; i++) {
    let item = items[i];
    let tokenId = item["id"];
    let impressionFee = item["impressionFee"];
    let creatorAddress = item["creatorAddress"];
    let mintLimit = item["mintLimit"];
    let mintCount = item["mintCount"];
    let isMintReady = item["isMintReady"];
    let isMintComplete = item["isMintComplete"];
    let tokenCID = item["tokenCID"];
    let authUUID = item["authUUID"];
    let mintPrice = item["mintPrice"];
    let itemMediaType = item["mediaType"];
    if (itemMediaType.toString() !== mediaType.toString()) {
      continue;
    }
    let creatorShares = item["creatorShares"];
    let holderShares = item["holderShares"];
    let uri = "https://the402.mypinata.cloud/ipfs/" + tokenCID;
    let metadata = await axios.get(uri);
    ans.push({
      description: metadata.data.description,
      name: metadata.data.name,
      mintPrice,
      imagePinata: metadata.data.imagePinata,
      image: metadata.data.image,
      royalty: metadata.data.creatorRoyaltyPercentage,
      authUUID,
      mintCount,
      mintLimit,
      isMintReady,
      isMintComplete,
      mediaType: itemMediaType,
      impressionFee,
      creatorAddress,
      tokenId,
      tokenCID,
      uri,
      royaltySharesInfo: {
        creatorShares: creatorShares,
        holderShares: holderShares,
      },
    });
  }
  return ans;
}

export async function getCurrentlyListedNFTs(address) {
  address = address.toLowerCase();
  let query = `
      {
        marketplaceItems(where:{seller:${'"' + address + '"'}}){
          id,
          item{
            id
          },
          delisted,
          price,
          amount,
          sold
        }
      }
    `;
  let data = await axios.post(
    graphURL,
    { query }
  );
  let items = data["data"]["data"]["marketplaceItems"];
  let ans = [];
  for (let i = 0; i < items.length; i++) {
    if(items[i].delisted || items[i].sold) {
      continue;
    }
    ans.push({
      marketItemId: items[i].id,
      tokenId: items[i].item.id,
      price: items[i].price,
      amount: items[i].amount
    });
  }
  return ans;
}

export async function getHeldNFTs(address) {
  address = address.toLowerCase();
  let query = `
        {
            user(id:${'"' + address + '"'}){
                ownedItems{
                    item{
                        id,
                        impressionFee,
                        creatorAddress,
                        mintLimit,
                        mintCount,
                        isMintReady,
                        isMintComplete,
                        tokenCID,
                        mintPrice,
                        mediaType,
                        creatorShares,
                        holderShares,
                        authUUID
                    },
                    editionsOwned
                }
            }
        }
    `;
  let data = await axios.post(
    graphURL,
    { query }
  );
  let user = data["data"]["data"]["user"];
  if (user == null) {
    return [];
  }
  let items = user["ownedItems"];
  let ans = [];
  for (let i = 0; i < items.length; i++) {
    let item = items[i]["item"];
    let tokenId = item["id"];
    let impressionFee = item["impressionFee"];
    let creatorAddress = item["creatorAddress"];
    let mintLimit = item["mintLimit"];
    let mintCount = item["mintCount"];
    let isMintReady = item["isMintReady"];
    let isMintComplete = item["isMintComplete"];
    let tokenCID = item["tokenCID"];
    let mintPrice = item["mintPrice"];
    let mediaType = item["mediaType"];
    let creatorShares = item["creatorShares"];
    let holderShares = item["holderShares"];
    let authUUID = item["authUUID"];
    let balance = items[i]["editionsOwned"];
    let uri = "https://the402.mypinata.cloud/ipfs/" + tokenCID;
    let metadata = await axios.get(uri);
    ans.push({
      description: metadata.data.description,
      name: metadata.data.name,
      authUUID,
      balance,
      mintPrice,
      imagePinata: metadata.data.imagePinata,
      image: metadata.data.image,
      royalty: metadata.data.creatorRoyaltyPercentage,
      mintCount,
      mintLimit,
      isMintReady,
      isMintComplete,
      mediaType,
      impressionFee,
      creatorAddress,
      tokenId,
      uri,
      royaltySharesInfo: {
        creatorShares: creatorShares,
        holderShares: holderShares,
      },
    });
  }
  return ans;
}

export async function getCreatedNFTs(address) {
  address = address.toLowerCase();
  let query = `
        {
            items(where:{creatorAddress:${'"' + address + '"'}}){
                id,
                impressionFee,
                creatorAddress,
                mintLimit,
                mintCount,
                isMintReady,
                isMintComplete,
                tokenCID,
                mintPrice,
                mediaType,
                creatorShares,
                holderShares,
                authUUID
            }
        }
    `;
  let data = await axios.post(
    graphURL,
    { query }
  );
  let items = data["data"]["data"]["items"];
  let ans = [];
  for (let i = 0; i < items.length; i++) {
    let item = items[i];
    let tokenId = item["id"];
    let impressionFee = item["impressionFee"];
    let creatorAddress = item["creatorAddress"];
    let mintLimit = item["mintLimit"];
    let mintCount = item["mintCount"];
    let isMintReady = item["isMintReady"];
    let isMintComplete = item["isMintComplete"];
    let tokenCID = item["tokenCID"];
    let mintPrice = item["mintPrice"];
    let mediaType = item["mediaType"];
    let creatorShares = item["creatorShares"];
    let holderShares = item["holderShares"];
    let authUUID = item["authUUID"];
    let uri = "https://the402.mypinata.cloud/ipfs/" + tokenCID;
    let metadata = await axios.get(uri);
    ans.push({
      description: metadata.data.description,
      name: metadata.data.name,
      authUUID,
      mintPrice,
      imagePinata: metadata.data.imagePinata,
      image: metadata.data.image,
      royalty: metadata.data.creatorRoyaltyPercentage,
      mintCount,
      mintLimit,
      isMintReady,
      isMintComplete,
      mediaType,
      impressionFee,
      creatorAddress,
      tokenId,
      uri,
      royaltySharesInfo: {
        creatorShares: creatorShares,
        holderShares: holderShares,
      },
    });
  }
  return ans;
}

export async function getMarketItems() {
  let query = `
        {
            marketplaceItems{
                id,
                item {
                    id,
                    tokenCID,
                    mediaType
                },
                seller {
                    id
                },
                price,
                amount,
                delisted,
                sold
            }
        }
    `;
  let data = await axios.post(
    graphURL,
    { query }
  );
  let items = data["data"]["data"]["marketplaceItems"];
  let ans = [];
  for (let i = 0; i < items.length; i++) {
    let marketItem = items[i];
    let marketItemId = marketItem["id"];
    let sellerAddress = marketItem["seller"]["id"];
    let price = marketItem["price"];
    let tokenId = marketItem["item"]["id"];
    let tokenCID = marketItem["item"]["tokenCID"];
    let delisted = marketItem["delisted"];
    let sold = marketItem["sold"];
    let amount = marketItem["amount"];
    let itemMediaType = marketItem["item"]["mediaType"];
    if (delisted || sold) {
      continue;
    }
    let uri = "https://the402.mypinata.cloud/ipfs/" + tokenCID;
    let metadata = await axios.get(uri);
    ans.push({
      description: metadata.data.description,
      name: metadata.data.name,
      imagePinata: metadata.data.imagePinata,
      image: metadata.data.image,
      royalty: metadata.data.creatorRoyaltyPercentage,
      mediaType: itemMediaType,
      marketItemId,
      tokenId,
      uri,
      sellerAddress,
      price,
      delisted,
      sold,
      amount,
    });
  }
  return ans;
}

export async function postEncryptedData(itemParams) {
  const url = "https://x9b60b84la.execute-api.us-west-2.amazonaws.com/dev";

  return await axios.post(url + "/post_encrypted_data", itemParams);
}

export async function getEncryptedItems(authSig) {
  const url = "https://x9b60b84la.execute-api.us-west-2.amazonaws.com/dev";

  return await axios.post(url + "/get_encrypted_data", {
    creatorAddress: authSig,
  });
}

export async function deleteEncryptedItems(creatorAddress, authSig, name) {
  const url = "https://x9b60b84la.execute-api.us-west-2.amazonaws.com/dev";

  return await axios.post(url + "/delete_encrypted_data", {
    creatorAddress: creatorAddress,
    name: name,
    authSig: authSig,
  });
}

export async function postFile(fileItem, key) {
  let itemURL = "https://ppv-nft-files.s3.us-west-2.amazonaws.com/";
  const REGION = "us-west-2";

  const client = new S3Client({
    region: REGION,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: REGION }),
      identityPoolId: "us-west-2:c986c479-bd11-43a0-a491-d49ee88f9f32",
      secretAccessKey: "LwpWCjQS1uyRDec5ixneSTma98uxHDJgOIpP7RyM",
      accessKeyId: "AKIA3U6SIGBW6WEYMJ4Z",
    }),
  });

  let putParams = {
    Bucket: "ppv-nft-files",
    Key: key,
    Body: fileItem,
  };

  console.log(putParams);

  await client
    .send(new PutObjectCommand(putParams))
    .then(function (response) {
      console.log(
        "Successfully created " +
          putParams.Key +
          " and uploaded it to " +
          putParams.Bucket +
          "/" +
          putParams.Key
      );
      itemURL += key;
    })
    .catch(function (error) {
      console.log("Error", error);
    });

  return itemURL;
}